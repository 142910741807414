<template>
  <component
    :is="as"
    class="inline-flex items-center currency"
    :class="{ 'line-through': lineThrough, rounded: rounded }"
    :data-after-content="value ? t(currency as any) : ''"
  >
    <span dir="ltr">{{ currencyValue }}</span>
  </component>
</template>
<script setup lang="ts">
const props = defineProps({
  value: {
    type: null,
    required: true,
  },
  as: {
    type: String,
    default: 'span',
  },
  fallback: {
    type: String,
    default: undefined,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  lineThrough: {
    type: Boolean,
    default: false,
  },
});
const currencyCode = inject(CONFIG_STORE_CURRENCY);
const innerValue = ref(props.value);
watch(
  () => props.value,
  newValue => {
    innerValue.value = newValue;
  },
);
const { t, n } = useI18n();

const currency = computed(() => currencyCode?.value || 'EG');
const currencyValue = computed(() => {
  if (innerValue.value === 0) {
    return props.fallback ? props.fallback : t('Free');
  }
  if (innerValue.value) {
    const price = Number(innerValue?.value) ?? 0;
    return n(price, {
      style: 'currency',
      currency: 'EGP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).replace('EGP', '');
  }

  if (props.fallback) return props.fallback;

  return t('toBeCalculated');
});
</script>
<style lang="postcss" scoped>
.currency::after {
  @apply ml-1 whitespace-nowrap;
  content: attr(data-after-content);
}
</style>
